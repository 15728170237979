import * as React from "react";
import {
  Show as AdminShow, SimpleShowLayout, ReferenceField, TextField, DateField, ReferenceManyField, useShowController,
  Datagrid
} from 'react-admin';

export default function Show (props) {
  const { record, loading } = useShowController(props);
  if (loading) return <></>;
  return <AdminShow {...props}>
    <SimpleShowLayout>
      <TextField source="code" />
      <DateField source="delivery_estimated_on" />
      <ReferenceField source="procurement_list_id" reference="procurement_lists" link={true}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceManyField reference="transfer_items" target="transfer_order_id" filter={{ transfer_order_id: record.id }}>
        <Datagrid>
          <TextField source="origin.name" />
          <TextField source="destination.name" />
          {/*TODO: display transfer item quantity <TextField source="quantity" /> */}
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </AdminShow>
};
