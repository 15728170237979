import React from 'react'
import { Admin, Resource } from 'react-admin'

import './App.css'

import * as Procurement from './resources/procurement';
import * as ProcurementList from './resources/procurement_list';
import * as PurchaseOrder from './resources/purchase_order';
import * as TransferOrder from './resources/transfer_order';
import * as Product from './resources/product';
import * as Space from './resources/space';
import * as Brand from './resources/brand';
import imageUploadingDataProvider from './providers/ImageUploadingProvider';
import authProvider from './providers/auth';
import LoginPage from './components/LoginPage';
import LogoutButton from './components/LogoutButton';
import deskeoTheme from './themes/deskeo';

export default function App () {
  return (
    <Admin title="Catalog" theme={deskeoTheme} dataProvider={imageUploadingDataProvider} authProvider={authProvider} loginPage={LoginPage} logoutButton={LogoutButton}>
      <Resource name="products" list={Product.List} create={Product.Create} edit={Product.Edit} show={Product.Show} />
      <Resource name="labels" />
      <Resource name="brands" list={Brand.List} create={Brand.Create} edit={Brand.Edit} show={Brand.Show}  />
      <Resource name="inventory_items" />
      <Resource name="purchase_orders" create={PurchaseOrder.Create} edit={PurchaseOrder.Edit} show={PurchaseOrder.Show} />
      <Resource name="transfer_orders" list={TransferOrder.List} create={TransferOrder.Create} edit={TransferOrder.Edit} show={TransferOrder.Show} />
      <Resource name="transfer_items" />
      <Resource name="procurements" create={Procurement.Create} edit={Procurement.Edit} show={Procurement.Show} />
      <Resource name="procurement_lists" list={ProcurementList.List} create={ProcurementList.Create} edit={ProcurementList.Edit} show={ProcurementList.Show} />
      <Resource name="purchase_items" />
      <Resource name="spaces" list={Space.List} create={Space.Create} edit={Space.Edit} show={Space.Show} />
    </Admin>
  );
}
