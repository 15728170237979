import React, { useState } from "react";
import {
  Edit as AdminEdit, SimpleForm, AutocompleteInput, ReferenceInput, TextInput, DateInput,
  ArrayInput, SimpleFormIterator, useDataProvider, NumberInput
} from 'react-admin';

export default function Edit(props) {
  const redirect = (basePath, id, data) => `/procurement_lists/${data.procurement_list_id}/show`
  const transfer_item_attributes = props?.history?.location?.state?.transfer_item_attributes;
  const { max: initialMax, product_id: initialProductId, origin_space_id: initialOriginSpaceId } = transfer_item_attributes || {}
  const [productId, setProductId] = useState(initialProductId);
  const [originId, setOriginId] = useState(initialOriginSpaceId);
  const [max, setMax] = useState(initialMax);
  const dataProvider = useDataProvider();
  const onOriginIdChange = function (id) {
    setOriginId(id);
    dataProvider.getOne('spaces', { id })
      .then(({ data }) => {
        const availableInventoryItems = data.inventory_items.filter(f => f.product_id === productId)
        setMax(availableInventoryItems.length);
      })
  }

  return <AdminEdit {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="code" />
      <DateInput source="delivery_estimated_on" />
      <ReferenceInput source="procurement_list_id" reference="procurement_lists">
        <AutocompleteInput optionValue="id" optionText="name" />
      </ReferenceInput>
      <ArrayInput source="transfer_items_attributes">
        <SimpleFormIterator>
          <ReferenceInput source="product_id" reference="products" label="Product" onChange={setProductId} initialValue={productId}>
            <AutocompleteInput optionValue="id" optionText="name" />
          </ReferenceInput>
          <ReferenceInput disabled={!productId} source="origin_id" reference="spaces" label="Origin" onChange={onOriginIdChange} initialValue={originId}>
            <AutocompleteInput optionValue="id" optionText="name" />
          </ReferenceInput>
          <ReferenceInput disabled={!originId} source="destination_id" reference="spaces" label="Destination">
            <AutocompleteInput optionValue="id" optionText="name" />
          </ReferenceInput>
          <NumberInput disabled={!originId} label="Quantity" source="quantity" min={0} max={max}></NumberInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </AdminEdit>
};
