import * as React from "react";
import {
  Show as AdminShow, TextField, ReferenceField, ReferenceArrayField, SingleFieldList, ChipField,
  Datagrid, ReferenceManyField, FunctionField, useShowController, Button, useRedirect, SimpleShowLayout, RichTextField
} from 'react-admin';
import { Box, TableCell, TableRow, TableHead } from '@material-ui/core';
import { CompactShowLayout } from 'ra-compact-ui';
import RemoteImageField from '../../components/RemoteImageField';
import CreateProcurementForm from '../../components/resources/product/show/CreateProcurementForm';

const PostShow = function ({ record: spaceRecord }) {
  const redirect = useRedirect();
  const addToExistingTransfer = function (id, spaceRec) {
    const representativeRecord = spaceRec.inventory_items[0];
    const totalItemsAtSpace = spaceRec.inventory_items.length;
    redirect('edit', '/transfer_orders', id, {}, {
      transfer_item_attributes: {
        origin_space_id: representativeRecord.space_id,
        product_id: representativeRecord.product_id,
        max: totalItemsAtSpace
      }
    })
  };
  const transferInventoryItem = function (record, redirect) {
    const representative_record = record.inventory_items[0];
    redirect('create', '/transfer_orders', null, {}, {
      transfer_item_attributes: {
        origin_space_id: representative_record.space_id,
        product_id: representative_record.product_id,
        max: record.inventory_items.length
      }
    });
  };

  return (
    <>
      <Button disabled={spaceRecord.inventory_items.length === 0} color="primary" variant="contained" onClick={(e) => { e.stopPropagation(); transferInventoryItem(spaceRecord, redirect) }}><>Create New Transfer</></Button>
      <h3>Click transfer name below to add to existing transfer</h3>
      <ReferenceManyField reference="transfer_orders" link={false}>
        <Datagrid rowClick={(recordId) => {
          addToExistingTransfer(recordId, spaceRecord)
        }}>
          <TextField source="code" />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

export default function Show(props) {
  const { record, loading } = useShowController(props);
  if (loading) return <></>;
  return (
    <AdminShow {...props}>
      <CompactShowLayout layoutComponents={[Box]}>
        <Box>
          <RemoteImageField source="photo_url" alt="name" />
        </Box>
        <Box display="flex">
          <Box mr="0.5em">
            <TextField source="sku" />
          </Box>
          <Box mr="0.5em" ml="0.5em">
            <TextField source="name" />
          </Box>
          <Box ml="0.5em">
            <ReferenceField source="brand_id" reference="brands" link={true}>
              <TextField source="name" />
            </ReferenceField>
          </Box>
        </Box>
        <Box display="flex">
          <Box mr="0.5em">
            <TextField source="category" />
          </Box>
          <Box mr="0.5em" ml="0.5em">
            <TextField source="subcategory" />
          </Box>
          <Box ml="0.5em">
            <ReferenceArrayField label="Tags" source="label_ids" reference="labels">
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          </Box>
        </Box>
        <Box>
          <CreateProcurementForm />
        </Box>
        <Box>
          <ReferenceManyField
            label="Available inventory items"
            reference="spaces"
            target="product_id"
            filter={{
              is_vacant: true,
              inventory_items: {
                product_id: record.id
              }
            }}
          >
            <Datagrid expand={<PostShow />} rowClick="show">
              <TextField label="Space Name" source="name" />
              <FunctionField label="Quantity" render={rec => rec.inventory_items.length} />
            </Datagrid>
          </ReferenceManyField>
        </Box>
      </CompactShowLayout>
    </AdminShow>
  )
}
