import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import env from '../../../env'

export default function ConfirmReceiptButton ({ record }) {
  const [open, setOpen] = useState(false)
  const [receiptConfirmed, confirmReceipt] = useState(false)
  const token = localStorage.getItem(env.TOKEN_KEY)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  const onClick = async () => {
    try {
      const response = await fetch(
        `${env.API_URL}/rest/purchase_orders/${record.id}/confirm_receipt`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      )
      if (response.status < 400) {
        setOpen(true)
        confirmReceipt(true)
      } else {
        throw new Error('Failed to confirm receipt.')
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message="Purchase Order Completed"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {!(record.delivered_at || receiptConfirmed) ? (
        <Button onClick={onClick} color="primary" variant="outlined">
          Confirm Receipt
        </Button>
      ) : null}
    </>
  )
}
