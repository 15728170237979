import * as React from "react";
import {
  Show as AdminShow, SimpleShowLayout, TextField, ReferenceManyField, Datagrid, EditButton, DeleteButton,
  ShowButton, useShowController
} from 'react-admin';
import ContentAdd from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

export default function Show (props) {
  const { record, loading } = useShowController(props);
  if (loading) return <></>;
  const { id } = props;

  const deleteRedirect = () => `/procurement_lists/${id}/show`

  const CreateProcurementButton = ({ record }) => (
    <Button
      component={Link}
      to={{
        pathname: '/procurements/create',
        state: { record: { procurement_list_id: record.id } },
      }}
    >
      <ContentAdd />&nbsp;Create Procurement
    </Button>
  );
  const CreatePurchaseOrderButton = ({ record }) => (
    <Button
      component={Link}
      to={{
        pathname: '/purchase_orders/create',
        state: { record: { procurement_list_id: record.id } },
      }}
    >
      <ContentAdd />&nbsp;Create Purchase Order
    </Button>
  );
  const CreateTransferOrderButton = ({ record }) => (
    <Button
      component={Link}
      to={{
        pathname: '/transfer_orders/create',
        state: { record: { procurement_list_id: record.id } },
      }}
    >
      <ContentAdd />&nbsp;Create Transfer Order
    </Button>
  );

  return (
    <AdminShow {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <ReferenceManyField label="Procurements" reference="procurements" target="procurement_list_id" filter={{ procurement_list_id: record.id }}>
          <Datagrid>
            <TextField sortable={false} label="Product" source="product.name" />
            <TextField sortable={false} label="Space" source="space.name" />
            <TextField sortable={false} source="quantity" />
            <TextField sortable={false} label="Outstanding" source="unfulfilled_quantity" />
            <TextField sortable={false} label="Purchased" source="purchased_quantity" />
            <TextField sortable={false} label="Transferred" source="transferred_quantity" />
            <EditButton />
            <DeleteButton redirect={deleteRedirect} />
          </Datagrid>
        </ReferenceManyField>
        <CreateProcurementButton />
        <ReferenceManyField reference="purchase_orders" target="procurement_list_id" filter={{ procurement_list_id: record.id }} link={true}>
          <Datagrid>
            <TextField source="code" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <CreatePurchaseOrderButton />
        <ReferenceManyField reference="transfer_orders" target="procurement_list_id" filter={{ procurement_list_id: record.id }} link={true}>
          <Datagrid>
            <TextField source="code" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <CreateTransferOrderButton />
      </SimpleShowLayout>
    </AdminShow>
  )
}
