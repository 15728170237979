import * as React from "react";
import {
  Show as AdminShow, SimpleShowLayout, ReferenceField, TextField, DateField, ReferenceManyField, useShowController,
  Datagrid
} from 'react-admin';
import ConfirmReceiptButton from '../../components/resources/purchase_order/ConfirmReceiptButton';

export default function Show (props) {
  const { record, loading } = useShowController(props);
  if (loading) return null;
  return <AdminShow {...props}>
    <SimpleShowLayout>
      <TextField source="code" />
      <DateField source="delivery_estimated_on" />
      <DateField source="delivered_at" />
      <ReferenceField source="procurement_list_id" reference="procurement_lists" link={true}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceManyField reference="purchase_items" target="purchase_order_id" filter={{ purchase_order_id: record.id }}>
        <Datagrid>
          <TextField source="product.name" />
          <TextField source="space.name" />
          <TextField source="quantity" />
        </Datagrid>
      </ReferenceManyField>
      <ConfirmReceiptButton />
    </SimpleShowLayout>
  </AdminShow>
};
