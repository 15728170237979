import * as React from "react";
import { Show as AdminShow, SimpleShowLayout, TextField, ReferenceManyField, Datagrid, useShowController } from 'react-admin';

export default function Show (props) {
  const { record, loading } = useShowController(props);
  if (loading) return null;
  return (
    <AdminShow {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="abbreviation" />
        <ReferenceManyField
          label="Products"
          reference="products"
          target="brand_id"
          sort={{ field: "created_at", order: "desc" }}
          filter={{ brand_id: record.id }}
        >
          <Datagrid rowClick="show">
            <TextField label="Name" source="name" />
            <TextField label="SKU" source="sku" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </AdminShow>
  )
}
