import React from 'react'
import {
  List as AdminList,
  TextField,
  useListContext,
  ShowButton,
  Filter,
  SelectInput
} from 'react-admin'
import { Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core'
import Empty from '../../components/Empty'

const cardStyle = {
  width: 290,
  minHeight: 290,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top'
};

const cardMediaStyle = {
  height: 300
};

const buttonStyle = {
  margin: '10px 0 0'
};

const ProductCard = ({ record }) => {
  return (
    <Card key={record.id} style={cardStyle}>
      <CardMedia style={cardMediaStyle} image={record.photo_url || "https://via.placeholder.com/500x300?text=Missing+photo"} title="" />
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          <TextField record={record} source="name" />
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <Typography>
              SKU
            </Typography>
            <Typography>
              <TextField record={record} source="sku" />
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              In Stock
            </Typography>
            <Typography>
              {record.available_inventory_items.length}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              Category
            </Typography>
            <Typography>
              <TextField record={record} source="category" />
            </Typography>
          </Grid>
          <ShowButton style={buttonStyle} basePath="/products" label="See Product Details" record={record} />
        </Grid>
      </CardContent>
    </Card>
  );
};

const ProductGrid = (props) => {
  const { ids, data } = useListContext();
  return (
    <div style={{ margin: '1em' }}>
      {ids.map(id => <ProductCard record={data[id]} />)}
    </div>
  );
};

export default function List(props) {
  return (
    <AdminList {...props}
      empty={<Empty />}
      sort={{ field: "created_at", order: "desc" }}
      filters={(
        <Filter {...props}>
          <SelectInput source="category" choices={[
            { id: 'formal', name: 'Formal' },
            { id: 'branding', name: 'Branding' },
            { id: 'informal', name: 'Informal' },
          ]} />
        </Filter>
      )}
    >
      <ProductGrid />
    </AdminList>
  )
}
