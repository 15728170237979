import { fetchUtils, HttpError } from 'react-admin'
import { stringify } from 'query-string'
import merge from '../utilities/merge'
import serializeParams from '../utilities/serializeParams'

import env from '../env'
import singular from '../utilities/singular'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem(env.TOKEN_KEY);
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const resourceQueryParams = {
  inventory_items: {
    include: {
      product: true,
      space: true
    }
  },
  procurements: {
    include: {
      procurement_list: true,
      space: true,
      product: true,
      unfulfilled_quantity: true,
      purchased_quantity: true,
      transferred_quantity: true
    }
  },
  products: {
    include: {
      photo_url: true,
      label_ids: true,
      labels: true,
      available_inventory_items: true
    }
  },
  purchase_items: {
    include: {
      product: true,
      space: true
    }
  },
  purchase_orders: {
    include: {
      purchase_items: true
    }
  },
  transfer_items: {
    include: {
      origin: true,
      destination: true
    }
  },
  transfer_orders: {
    include: {
      transfer_items: true
    }
  },
  spaces: {
    include: {
      inventory_items: true
    }
  }
};

const DataProvider = {
  httpClient: httpClient,
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    const queryParams = {
      [`order[${field}]`]: order,
      limit: JSON.stringify(perPage),
      offset: JSON.stringify((page - 1) * 10),
    }
    for (const key in params.filter) {
      queryParams[`where[${key}]`] = params.filter[key]
    }
    const query = merge({}, queryParams, resourceQueryParams[resource])

    const dataUrl = `${env.API_URL}/rest/${resource}?${serializeParams(query)}`
    const dataResponse = await httpClient(dataUrl)

    const countUrl = `${env.API_URL}/rest/${resource}/calculate?${serializeParams(query)}&select[count]=id&distinct=id`
    const countResponse = await httpClient(countUrl)
    return {
      data: dataResponse.json,
      total: countResponse.json[0],
    }
  },

  getOne: async (resource, params) => {
    const query = serializeParams(resourceQueryParams[resource])
    const response = await httpClient(`${env.API_URL}/rest/${resource}/${params.id}?${query}`)
    return {
      data: response.json
    }
  },

  getMany: async (resource, params) => {
    const queryParams = {
      [`where[id]`]: params.ids,
      limit: JSON.stringify(1000)
    }
    const query = merge({}, queryParams, resourceQueryParams[resource])
    const response = await httpClient(`${env.API_URL}/rest/${resource}?${serializeParams(query)}`)
    return {
      data: response.json
    }
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const queryParams = {
      [`order[${field}]`]: order,
      limit: JSON.stringify(perPage),
      offset: JSON.stringify((page - 1) * 10),
      where: {
        ...params.filter
      },
      distinct: true // because standardapi does full outer join, ensure distinct rows
    }
    const query = merge({}, queryParams, resourceQueryParams[resource])
    const serialized = serializeParams(query);
    const response = await httpClient(`${env.API_URL}/rest/${resource}?${serialized}`)
    const countResponse = await httpClient(`${env.API_URL}/rest/${resource}/calculate?${serialized}&select[count]=id&distinct=id`)
    return {
      data: response.json,
      total: countResponse.json[0],
    }
  },

  update: async (resource, params) => {
    const singularResource = singular(resource)
    const patchParams = {}
    for (const key of Object.keys(params.data)) {
      if (params.data[key] !== params.previousData[key]) patchParams[key] = params.data[key]
    }
    const response = await httpClient(`${env.API_URL}/rest/${resource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        [singularResource]: patchParams
      }),
    })
    return {
      data: response.json
    }
  },

  updateMany: async (resource, params) => {
    const query = {
      where: JSON.stringify({ id: params.ids }),
    }
    const response = await httpClient(`${env.API_URL}/rest/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    })
    return {
      data: response.json
    }
  },

  create: async (resource, params) => {
    const singularResource = singular(resource)
    try {
      const response = await httpClient(`${env.API_URL}/rest/${resource}`, {
        method: 'POST',
        body: JSON.stringify({
          [singularResource]: params.data
        }),
      })
      return {
        data: response.json
      }
    } catch (error) {
      const errorKeys = Object.keys(error.body.errors)
      // Trigger human readable error to view.
      throw new HttpError(`${errorKeys[0]} ${error.body.errors[errorKeys[0]]}`)
    }
  },

  delete: async (resource, params) => {
    await httpClient(`${env.API_URL}/rest/${resource}/${params.id}`, {
      method: 'DELETE',
    })
    return {
      data: {}
    }
  },

  deleteMany: async (resource, params) => {
    for (const id of params.ids) {
      await httpClient(`${env.API_URL}/rest/${resource}/${id}`, {
        method: 'DELETE',
      })
    }
    return {
      data: []
    }
  },
};


export default DataProvider
